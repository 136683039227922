// TODO: consider: allow binding to limit available classes on element, reduce class bloat
let mode = config.style.responsive.responsiveClassMode;
let responsiveClassPrefix = config.style.responsive.responsiveClassPrefix;
let classMap = config.style.responsive.classMap;

/**
 * Method to get a responsive class by key in the map. right now just adds prefix.
 * We can make it smarter later if we need to
 * @param key
 * @returns {string}
 */
let getFullResponsiveClass = (key) => {
  return responsiveClassPrefix + key;
};

/**
 * Given an element and a width, updates responsive classes on the element
 * @param el
 * @param width
 */
let updateElementResponsiveClasses = (el, width) => {
  for (let [key, value] of Object.entries(classMap)) {
    let condition = mode === "mobileFirst" ? value > width : value < width;
    let method = condition ? "add" : "remove";

    el.classList[method](getFullResponsiveClass(key));
  }
};

/**
 * Handler for our resize observer
 * @param entries
 */
const observerHandler = (entries) => {
  for (let entry of entries) {
    let size = entry.borderBoxSize.inlineSize
      ? entry.borderBoxSize.inlineSize
      : entry.borderBoxSize[0].inlineSize;
    updateElementResponsiveClasses(entry.target, size);
  }
};

// create an instance of resizeObserver for our use
let observer;
if (!utilities.isSSR()) {
  observer = new ResizeObserver(observerHandler);
} else {
  observer = false;
}

// on mount, tell observer to watch, when unmounting - stop watching
export default {
  mounted(el) {
    try {
      if (observer) {
        observer.observe(el);
      }
    } catch (e) {
      console.log("can not do responsive class on el", el);
    }
  },
  beforeUnmount(el) {
    try {
      if (observer) {
        observer.unobserve(el);
      }
    } catch (e) {
      console.log("can not do responsive class on el", el);
    }
  },
};
